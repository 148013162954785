import {IMenuItem} from "src/types";
export {months} from "@/constants/months";

export const MENU_ITEMS: IMenuItem[] = [
    {icon: 'statistics', label: 'menu.statistics', to: '/statistics'},
    {icon: 'attention', label: 'menu.register-of-violations', to: '/violations'},
    // {icon: 'list', label: 'menu.register-of-job-templates', to: '/tasks/templates'},
    {icon: 'catalog', label: 'menu.job-execution-log', to: '/tasks/logs'},
    // {icon: 'document', label: 'menu.register-of-document-templates', to: '/documents'},
    // {icon: 'reference-books', label: 'menu.directories', to: '/dictionaries'},
    {icon: 'settings', label: 'menu.settings', to: '/settings'},
];

export const SUR_ITEMS = [
    {
        code: 0,
        icon: 'point',
        colorHex: '#FFFFFF',
        colorStyle: 'white',
        labelKey: 'sur.no_data',
    },
    {
        code: 1,
        icon: 'point',
        colorHex: '#52CB50',
        colorStyle: 'success',
        labelKey: 'sur.green',
    },
    {
        code: 2,
        icon: 'point',
        colorHex: '#F3C10E',
        colorStyle: 'warning',
        labelKey: 'sur.yellow',
    },
    {
        code: 3,
        icon: 'point',
        colorHex: '#F54848',
        colorStyle: 'danger',
        labelKey: 'sur.red',
    }
];

export const VIOLATIONS_STATUSES = [
    {
        code: 0,
        colorHex: '#E2FFD7',
        colorStyle: 'violation-opened',
        labelKey: 'violation_status.opened',
    },
    {
        code: 1,
        colorHex: '#52CB50',
        colorStyle: 'violation-closed',
        labelKey: 'violation_status.closed',
    }
];

export const SENDING_STATUSES = [
    {
        code: 0,
        colorHex: '#FEC50C',
        colorStyle: 'sending-not-sent',
        labelKey: 'sending_status.not_sent',
    },
    {
        code: 1,
        colorHex: '#F54848',
        colorStyle: 'sending-error',
        labelKey: 'sending_status.error',
    },
    {
        code: 2,
        colorHex: '#3AA1EC',
        colorStyle: 'sending-sent',
        labelKey: 'sending_status.sent',
    },
    {
        code: 3,
        colorHex: '#3AA1EC',
        colorStyle: 'sending-read',
        labelKey: 'sending_status.read',
    }
];
