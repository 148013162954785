import {Vue, Component} from 'vue-property-decorator';
import {MENU_ITEMS} from '@/constants';
import {IMenuItem} from "@/types";
import LocaleSwitch from "@/components/LocaleSwitch/LocaleSwitch.vue";
import UserProfile from "@/components/UserProfile/UserProfile.vue";

@Component({
    components: {
        LocaleSwitch,
        UserProfile,
    },
})
export default class AppView extends Vue {
    public get asideItems(): IMenuItem[] {
        return MENU_ITEMS.map((item: IMenuItem) => ({
            icon: item.icon,
            to: item.to,
            label: this.$t(item.label) as string,
        }));
    }
}
