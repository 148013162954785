import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from "moment";
import ruLocale from '@/locales/ru.json';
import enLocale from '@/locales/en.json';

import ruKitLocale from '@dtt/ui-kit/src/locale/lang/ru.json';
import enKitLocale from '@dtt/ui-kit/src/locale/lang/en.json';


Vue.use(VueI18n);

moment.locale(localStorage.getItem('lang') || 'en');
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en',
    fallbackLocale: 'ru',
    messages: {
        ru: { ...ruLocale, ...ruKitLocale },
        en: { ...enLocale, ...enKitLocale },
    },
});

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    moment.locale(lang);
    localStorage.setItem('lang', lang);
    return lang;
}

export { i18n, setI18nLanguage };
