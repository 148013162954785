import {Vue, Component, Prop} from 'vue-property-decorator';
import {IContextMenuItem} from 'src/types';
import {i18n, setI18nLanguage} from '@/i18n';

@Component({})
export default class LocaleSwitch extends Vue {
    @Prop({type: String, default: '#59606A'}) arrowColor!: string;

    public locales: IContextMenuItem[] = [];
    public selectedLocale: IContextMenuItem;

    constructor() {
        super();

        this.locales = [{
            icon: 'country-russia',
            disabled: false,
            label: 'РУС',
            value: 'ru',
        },{
            icon: 'country-uk',
            disabled: false,
            label: 'ENG',
            value: 'en',
        }];

        const index = this.locales.findIndex((option: IContextMenuItem) => option.value === i18n.locale);
        if (index > -1) {
            this.selectedLocale = this.locales[index];
        } else {
            this.selectedLocale = this.locales[0];
        }
    }

    public selectLocale(item: IContextMenuItem): void {
        this.selectedLocale = item;
        setI18nLanguage(item.value);
    }
}
