import {Vue, Component} from 'vue-property-decorator';
import {MENU_ITEMS} from "@/constants";
import LanguageSelector from "@/components/LocaleSwitch/LocaleSwitch.vue";

@Component({
    components: {
        LanguageSelector,
    },
})
export default class HomeView extends Vue {
    public menuItems = MENU_ITEMS;

    public navigateTo(path: string): void {
        this.$router.push(path);
    }
}
