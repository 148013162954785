import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from "@/views/Home/HomeView.vue";
import AppView from "@/views/App/AppView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/app',
    component: AppView,
    children: [
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics/StatisticsView.vue'),
      },
      {
        path: '/violations',
        name: 'violations',
        component: () => import(/* webpackChunkName: "violations" */ '@/views/ViolationsList/ViolationsListView.vue'),
      },
      {
        path: '/violations/:id',
        component: () => import(/* webpackChunkName: "violation" */ '@/views/Violation/ViolationView.vue'),
        children: [
          {
            path: '',
            redirect: 'general-information',
          },
          {
            path: 'general-information',
            component: () => import(/* webpackChunkName: "violation/general-information" */ '@/views/Violation/GeneralInformation/GeneralInformationView.vue'),
          },
          {
            path: 'sending',
            component: () => import(/* webpackChunkName: "violation/general-information" */ '@/views/Violation/Sending/SendingView.vue'),
          },
          {
            path: 'history',
            component: () => import(/* webpackChunkName: "violation/general-information" */ '@/views/Violation/History/HistoryView.vue'),
          },
        ],
      },
      {
        path: '/tasks/templates',
        name: 'tasks/templates',
        component: () => import(/* webpackChunkName: "tasks/templates" */ '../views/Tasks/TasksTemplates/TasksTemplatesView.vue'),
      },
      {
        path: '/tasks/logs',
        name: 'tasks/logs',
        component: () => import(/* webpackChunkName: "tasks/logs" */ '../views/Tasks/TasksLogs/TasksLogsView.vue'),
      },
      {
        path: '/documents',
        name: 'documents',
        component: () => import(/* webpackChunkName: "documents" */ '../views/Documents/DocumentsView.vue'),
      },
      {
        path: '/dictionaries',
        name: 'dictionaries',
        component: () => import(/* webpackChunkName: "dictionaries" */ '../views/Dictionaries/DictionariesView.vue'),
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsView.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
